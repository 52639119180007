import revive_payload_client_GXnHgItrjI from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_90FYMwrGyC from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_y6jqda303u from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_T8ZbLRd7gJ from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.17.2_typescript@5.5.4_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_RqFZB1z0Kf from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_QgovlFjV3a from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_S7XSjIsMVN from "/opt/render/project/src/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_2w7ffxsvttybsvgidlh6tmnrnm/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_QiY79SFQiR from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Cm4hTmS0i9 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_rLZYGQcK3W from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_init_hVDiYuZhdY from "/opt/render/project/src/apps/app/plugins/1.init.ts";
import i18n_VfGcjrvSkj from "/opt/render/project/src/apps/app/plugins/i18n.ts";
import sentry_3AyO8nEfhE from "/opt/render/project/src/apps/app/plugins/sentry.ts";
import snackbar_G4WEaUIGl5 from "/opt/render/project/src/apps/app/plugins/snackbar.ts";
import zodErrors_YzNmqC0Kau from "/opt/render/project/src/apps/app/plugins/zodErrors.ts";
import plugin_auto_pageviews_client_CRVVMAZpjw from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_GXnHgItrjI,
  unhead_90FYMwrGyC,
  router_y6jqda303u,
  plugin_vue3_T8ZbLRd7gJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_RqFZB1z0Kf,
  plugin_client_QgovlFjV3a,
  plugin_S7XSjIsMVN,
  switch_locale_path_ssr_QiY79SFQiR,
  i18n_Cm4hTmS0i9,
  chunk_reload_client_rLZYGQcK3W,
  _1_init_hVDiYuZhdY,
  i18n_VfGcjrvSkj,
  sentry_3AyO8nEfhE,
  snackbar_G4WEaUIGl5,
  zodErrors_YzNmqC0Kau,
  plugin_auto_pageviews_client_CRVVMAZpjw
]