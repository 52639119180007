<template>
    <Html :lang="locale" class="min-h-screen" />
    <Body class="h-full bg-gray-100" />

    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>

    <NuxtLoadingIndicator :color="_primary[600]" :height="2" :duration="1000" />

    <AppSnackbar />
    <AppPrivacyPolicyBanner v-if="showPrivacyPolicyBanner" />

    
</template>

<script setup lang="ts">
import { Locale } from '@prisma/client'
import { _primary } from '#tailwind-config/theme/colors'

const route = useRoute()
const nuxtApp = useNuxtApp()
const appStore = useAppStore()
const { locale, t } = useI18n()

// TODO: Find another way to hide sidebar, this causes flickers
nuxtApp.hook('page:finish', () => {
    appStore.hideSidebar = !!route.meta.hideSidebar
})

useHead({
    link: [
        { rel: 'canonical', href: 'https://app.efirma.bg' },
        { rel: 'icon', type: 'image/png', href: '/favicon-32x32.png' },
    ],
})

useSeoMeta({
    titleTemplate: (title) => {
        return title ? `${title} · eFirma` : `eFirma`
    },
    description: () => t('meta.description'),
    ogUrl: 'https://app.efirma.bg',
    ogTitle: 'eFirma.bg',
    ogType: 'website',
    ogImage: 'https://efirma.bg/images/og-image.png',
    ogDescription: () => t('meta.description'),
    ogLocale: () => (locale.value === Locale.BG ? 'bg_BG' : 'en_US'),
    ogLocaleAlternate: () => (locale.value === Locale.BG ? 'en_US' : 'bg_BG'),
    twitterTitle: 'eFirma.bg',
    twitterDescription: () => t('meta.description'),
    twitterImage: 'https://efirma.bg/images/og-image.png',
    twitterCard: 'summary',
})

const showPrivacyPolicyBanner = computed(() => {
    return appStore.isPrivacyConcentAgreed === undefined
})
</script>

<style>
html {
    font-family: 'Inter', sans-serif;
}
</style>
